import AOS from "aos";

function aosInit() {
    //aos config
    var aosElements = document.querySelectorAll(".aos-fade-up, .aos-item, .aos-delay");

    for (let i = 0; i < aosElements.length; i++) {
        aosElements[i].setAttribute("data-aos", "fade-up");
    }

    if (window.location.hash.length && !document.querySelector("#webinar-video-modal")) {
        $("body").addClass("disable-aos");
    }

    AOS.init({
        offset: 150,
        duration: 800,
        easing: "ease-out-quad",
        once: true
    });
}

export default { aosInit };
