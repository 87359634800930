class BtnCollapsible {
    constructor() {
        this.dropdowns = document.querySelectorAll("[data-collapsible]");
        if (!this.dropdowns.length) return;

        this.init();
    }

    buttonClick() {
        const isExpanded = this.button.getAttribute("aria-expanded") === "true";
        this.button.setAttribute("aria-expanded", !isExpanded);
        this.classList.toggle("inner-show", !isExpanded);
        this.menu.toggleAttribute("inert");

        if (!isExpanded) {
            this.items[0].focus();
            this.currentIndex = 0;
        }
    }

    buttonKeydown(e) {
        if (e.key === "Enter" || e.key === " " || e.key === "ArrowDown") {
            e.preventDefault();
            this.button.click();
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            this.closeDropdown();
        }
    }

    menuKeydown(e) {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            this.currentIndex = (this.currentIndex + 1) % this.items.length;
            this.items[this.currentIndex].focus();
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
            this.items[this.currentIndex].focus();
        } else if (e.key === "Escape") {
            this.closeDropdown();
        }
    }

    documentListener(e) {
        this.dropdowns.forEach(function(dropdown) {
            if (!dropdown.contains(e.target)) {
                dropdown.closeDropdown();
            }
        });
    }

    closeDropdown() {
        this.button.setAttribute("aria-expanded", "false");
        this.currentIndex = -1;
        this.menu.setAttribute("inert", "");
        this.classList.remove("inner-show");
    }

    init() {
        this.dropdowns.forEach(
            function(dropdown) {
                dropdown.button = dropdown.querySelector(".collapsible__btn");
                dropdown.menu = dropdown.querySelector(".collapsible__content");
                dropdown.items = dropdown.menu.querySelectorAll("a");
                dropdown.closeDropdown = this.closeDropdown;
                dropdown.currentIndex = -1;

                dropdown.button.addEventListener("click", this.buttonClick.bind(dropdown));
                dropdown.button.addEventListener("keydown", this.buttonKeydown.bind(dropdown));
                dropdown.menu.addEventListener("keydown", this.menuKeydown.bind(dropdown));
            }.bind(this)
        );

        //document.addEventListener("focusin", this.documentListener.bind(this));
    }
}

export default BtnCollapsible;
