import createCalendar from "../../utils/addToCalendar.js";
import { TempusDominus, DateTime } from "@eonasdan/tempus-dominus";
import DOMPurify from "dompurify";

function reminders() {
    // Create new Date instance

    var reminderElement = document.querySelector('.injection-reminders');
    if (!reminderElement) return;

    const datepicker = new TempusDominus(document.getElementById("date"), {
        localization: {
            format: "L",
            locale: "en",
            dayViewHeaderFormat: { month: "long", year: "numeric" }
        },
        display: {
            icons: {
                previous: "glyphicon glyphicon-chevron-left",
                next: "glyphicon glyphicon-chevron-right"
            },
            components: {
                clock: false,
                hours: false,
                minutes: false,
                seconds: false
            },
            theme: "light",
            placement: "top"
        },
        restrictions: {
            minDate: new DateTime()
        },
        defaultDate: new Date()
    });

    //Overwritting the calender to have DOW be 1 letter
    document.getElementById("date").addEventListener("click", () => {
        document.querySelectorAll(".dow.no-highlight").forEach(day => {
            day.textContent = day.textContent[0];
        });
    });

    //disabling the text input
    $("input[name=date]").on("keydown keyup paste", function(e) {
        e.preventDefault();
    });

    $("#injection-reminders-carousel, #reminders-carousel").on("submit", function(e) {
        e.preventDefault();
        var date = datepicker.viewDate;
        date.setMinutes($("#Minute").val());
        date.setHours(parseInt($("#Hour").val()) + parseInt($('[name="ampm"]:checked').val()));
        date.setSeconds(0);

        var finalURL = DOMPurify.sanitize(document.URL);

        var title = reminderElement.dataset.reminderTitle;
        var description = `${reminderElement.dataset.reminderDescription}${(reminderElement.dataset.includeUrl) ? " " + finalURL : ""}`;

        if($('.duration-radios').length > 0) {
	        if($('input[name=frequency]:checked').length > 0) {
	            $('.duration-radios').removeClass('hasError');
	        } else {
	            $('.duration-radios').addClass('hasError');

                $('.duration-radios').one('change', function(){
                    $('.duration-radios').removeClass('hasError');
                })

	            return;
	        }
        }

        var calendarItems = createCalendar({
            data: {
                title: title,
                start: date,
                duration: 60,
                description: `${description}`,
                frequency: "RRULE:FREQ=WEEKLY;INTERVAL=" + $('[name="frequency"]:checked').val()
            }
        });

        var calType = "." + $('[name="calendar-type"]:checked').attr("id"),
            href = $(calendarItems)
                .find(calType)
                .attr("href");

        if (calType == ".google" || calType == ".yahoo") {
            $("#thirdPartyMod").modal("show");
            $(".websiteBtn").data("url", href);
        } else {
            window.open(DOMPurify.sanitize(href), "_blank");
        }
    });
}

export default reminders;
