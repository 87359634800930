import FormValidation from "../plugins/validate";
import { load } from "recaptcha-v3";
import { trackEvent } from "../modules/track";

class BaseForm {
    constructor(rootElement) {
        if (!rootElement) return;
        this.rootElement = rootElement;
        this.progressBar = rootElement.querySelector(".progressbar");

        this.formElement = rootElement.querySelector("form");
        if (this.formElement) {
            this.fieldsValidateByApi = this.formElement.querySelectorAll("[data-api]");
            this.steps = this.formElement.querySelectorAll("[data-form-step]");
            if (this.formElement.querySelector(".form-step-back")) this.formElement.querySelector(".form-step-back").onClick(this.handlePrevStep);
            if (this.formElement.querySelector(".form-step-next")) this.formElement.querySelector(".form-step-next").onClick(this.handleNextStep);
            if (this.formElement.querySelector(".form-check")) this.formElement.querySelector(".form-check").onClick(this.handleCheckForm);
            if (this.formElement.querySelector(".form-submit")) this.formElement.querySelector(".form-submit").onClick(this.handleSubmitForm);
            if (this.steps.length) {
                this.currentStep = 1;
                this.steps[0].classList.add("active");
            }
        }
    }

    onValidateForm() {
        const $this = this;
        if ($this.form) {
            new FormValidation({
                selector: $this.form,
                submitForm: false,
                invalidScroll: true,
                events: {
                    doSuccess() {
                        // Checking validation by API server. Filtering out disabled inputs from validation
                        if (Array.from($this.formElement.querySelectorAll("input.invalid")).filter(element => !element.disabled).length == 0) {
                            $this.onSubmitForm();
                        }
                    },
                    doError() {}
                }
            });
        }
    }

    onValidateByApi() {
        if (!this.fieldsValidateByApi) {
            return;
        }

        if (this.fieldsValidateByApi.length == 0) {
            return;
        }

        for (let i = 0; i < this.fieldsValidateByApi.length; i++) {
            let inputItem = this.fieldsValidateByApi[i];
            inputItem.addEventListener("blur", e => {
                e.preventDefault();
                let url = inputItem.getAttribute("data-api");
                if (url && url.length > 0 && e.target.value && e.target.value.length > 0) {
                    url += e.target.value;
                    this.handleApiValidation(url, inputItem);
                }
            });
        }
    }

    handleApiValidation(url, inputItem) {
        fetch(url, {
            method: "GET",
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }).then(response => {
            if (response.ok) {
                response.text().then(text => {
                    let result = JSON.parse(text);
                    if (result.zipCode) {
                        this.handleZipcodeError(result, inputItem);
                    } else if (result.valid != null) {
                        this.handleEmailError(result, inputItem);
                    }
                });
            }
        });
    }

    handleEmailError(result, inputItem) {
        if (!result.valid) {
            const dataValidateId = inputItem.getAttribute("data-validate-id");
            const errMsgPattern = inputItem.getAttribute("data-error-pattern");
            const errEmailElm = '<div id="' + dataValidateId + '" class="error error-container">' + errMsgPattern + "</div>";
            if (inputItem.parentElement.querySelector(".error")) {
                inputItem.parentElement.querySelector(".error").remove();
            }
            inputItem.classList.add("invalid");
            inputItem.parentElement.insertAdjacentHTML("beforeend", errEmailElm);
        } else {
            if (inputItem.parentElement.querySelector(".error")) {
                inputItem.classList.remove("invalid");
                inputItem.parentElement.querySelector(".error").remove();
            }
        }
    }

    handleZipcodeError(result, inputItem) {
        if (result.zipCode.error) {
            const dataValidateId = inputItem.getAttribute("data-validate-id");
            const dataErrMsg = inputItem.getAttribute("data-error-require");
            const errZipcodeElm = '<div id="' + dataValidateId + '" class="error error-container">' + dataErrMsg + "</div>";
            if (inputItem.parentElement.querySelector(".error")) {
                inputItem.parentElement.querySelector(".error").remove();
            }
            inputItem.classList.add("invalid");
            inputItem.parentElement.insertAdjacentHTML("beforeend", errZipcodeElm);
        } else {
            if (inputItem.parentElement.querySelector(".error")) {
                inputItem.classList.remove("invalid");
                inputItem.parentElement.querySelector(".error").remove();
            }
        }
    }

    onSubmitForm() {
        if (!this.formElement) {
            return;
        }

        const formAction = this.formElement.action;
        const formMethod = this.formElement.method;
        if (!formAction || !formMethod) {
            return;
        }
        const formData = this.serializeFormData();
        const headers = { "Content-Type": "application/x-www-form-urlencoded" };

        let errorMessageModalDialog = document.getElementById("errorMessageModalDialog");
        let signupFormItemGroups = document.getElementsByClassName("dupi-form__group");
        let successMessage = document.getElementsByClassName("dupixent-confirmationsection");
        var recaptchaV3String = $("button[name='recaptcha']").val();
        var recaptchaV3Boolean = recaptchaV3String === "true";

        if (formAction.includes("ghost") || !recaptchaV3Boolean) {
            let token = false;
            this.postData(formAction, formMethod, formData, headers, token, recaptchaV3Boolean).then(data => {
                this.successOrError(data, errorMessageModalDialog, signupFormItemGroups, successMessage);
            });
        } else {
            const recaptchaID = document.querySelector(".submit").dataset.sitekey;
            load(recaptchaID).then(recaptcha => {
                recaptcha.execute("submit").then(token => {
                    this.postData(formAction, formMethod, formData, headers, token, recaptchaV3Boolean).then(data => {
                        this.successOrError(data, errorMessageModalDialog, signupFormItemGroups, successMessage);
                    });
                });
            });
        }
    }

    async postData(url, method, data = "", headers = {}, token, recaptcha) {
        if (!url || !method) {
            return;
        }
        if (recaptcha) {
            data = data + "&g-recaptcha-response=" + token;
        }
        const response = await fetch(url, {
            method: method,
            cache: "no-cache",
            credentials: "same-origin",
            headers: headers,
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: data
        });

        return response;
    }

    successOrError(data, errorMessageModalDialog, signupFormItemGroups, successMessage) {
        if (data.status == 200 && signupFormItemGroups && successMessage) {
            //scrolling on signup form
            let signupForm = document.querySelector(".crm-signup");

            if (signupForm) {
                setTimeout(() => {
                    signupForm.scrollIntoView({ block: "center", behavior: "smooth" });
                }, 250);
            }
            document.querySelector("body").classList.add("crm-pod-success");
            this.setDisplayItems(signupFormItemGroups, "none");
            this.setDisplayItems(successMessage, "block");
            let ineligbleURL = new URLSearchParams(window.location.search);
            let ineligbleParam = ineligbleURL.has("copayIneligible");
            if (ineligbleParam) {
                //click signup submit
                trackEvent({
                    event: "email_signup_submit",
                    event_name: "email_signup_submit"
                });
            }
        } else if (errorMessageModalDialog) {
            if (document.querySelector("body").classList.contains("crm-pod-sucess")) {
                document.querySelector("body").classList.remove("crm-pod-success");
            }
            errorMessageModalDialog.style.display = "block";
            errorMessageModalDialog.classList.add("in");
        }
    }

    serializeFormData() {
        try {
            return Array.from(new FormData(this.formElement), e => e.map(encodeURIComponent).join("=")).join("&");
        } catch (e) {
            return "";
        }
    }

    jsonFormData(formElement) {
        try {
            return JSON.stringify(Object.fromEntries(new FormData(formElement)));
        } catch (e) {
            return "";
        }
    }

    async handleStepChange(newStep) {
        let curStepElement = this.formElement.querySelector(`[data-form-step=${this.currentStep}]`);
        curStepElement.classList.remove("active");
        let newStepElement = this.formElement.querySelector(`[data-form-step=${newStep}]`);
        newStepElement.classList.add("active");
        this.currentStep = newStep;
    }

    handleNextStep() {
        this.handleStepChange(this.currentStep + 1);
    }

    handlePrevStep() {
        return null;
    }

    handleCheckForm() {
        return null;
    }

    handleSubmitForm() {
        return null;
    }

    currentStep() {
        // this.steps
    }
}

export default BaseForm;
