import updateProgressbar from "./helpers/updateProgressBar";
import triggerISI from "./helpers/triggerIsi";
import removeTags from "./helpers/removeTags";
import progressBar from "./helpers/progressBar";
import scrollToElement from "../../utils/scrollToElement";
/* -------------------
BEGIN core-ddg
Sitecore Resource: 1240b65c-23f5-4b96-aa8c-9211499708d8
   -------------------*/
function ddgCore() {
    $(document).ready(function() {
        $(".flip-container .back-layout").attr("inert", "");

        $(".section-flipper__container .ddg-flip-toggle").on("click", function(e) {
            e.preventDefault();

            const toggle = $(this);
            const container = toggle.parents(".flip-container");
            const front = container.find(".frontside");
            const current = $("#" + $(this).attr("data-flip"));

            current.toggleClass("hide");
            container.toggleClass("active");

            if (current.hasClass("hide")) {
                current.attr("inert", "");
                front.removeAttr("inert");
            } else {
                current.removeAttr("inert");
                front.attr("inert", "");
            }

            if (container.offset().top < $(window).scrollTop()) {
                scrollToElement(container);
            }
        });
        //Indicator for patient or caregiver questions
        $(".personal-info-step input").change(function() {
            $(".carousel-inner").attr("data-personal-info", $(this).val());
        });
        $("#carousel-ddg")
            .carousel({
                interval: false,
                wrap: false,
                pause: true
            })
            .on("slid.bs.carousel", function(e) {
                courselControls(e);
            });

        //prevent ddg carousel section to scroll left or right in mobile screens
        if (document.getElementById("carousel-ddg")) {
            document.getElementById("carousel-ddg").addEventListener("touchstart", e => {
                let touchPageX = e.touches[0].pageX ? e.touches[0].pageX : e.pageX;

                // is not near edge of view, exit
                if (touchPageX > 10 && touchPageX < window.innerWidth - 10) return;

                // prevent swipe to navigate gesture
                e.preventDefault();
            });
        }

        //lets both mobile and desktop checkboxes work together (when a carousel is used on mobile)
        var duplicateCheckboxes = ["journey-3-checkbox-checkboxlist", "impact-5-checkboxlist"];
        $('input[type="checkbox"]').change(function() {
            let checkboxName = $(this).prop("name");

            if (duplicateCheckboxes[0].includes(checkboxName) || duplicateCheckboxes[1].includes(checkboxName)) {
                $("input[name=" + checkboxName + "][value='" + $(this).val() + "']").prop("checked", $(this).is(":checked"));
            }
        });

        $("input[type=radio]").click(function() {
            $(this)
                .closest(".radio-group")
                .addClass("chked");
            delayNextSlide();
        });

        //Tooltips for checkbox sections
        $(window).on("load", function() {
            $('#carousel-ddg [data-toggle="tooltip"]').tooltip({
                trigger: "hover",
                container: "body"
            });
        });

        //Range slider stuff
        var sliderContainer = $('.slidecontainer input[type="range"]');
        sliderContainer
            .on("input change click touchend", function() {
                var currentVal = parseInt($(this).val()),
                    dataLists = $(this)
                        .siblings(".range-list")
                        .children(),
                    outputView = $(this).siblings(".range-output");

                $(this)
                    .closest(".slidecontainer")
                    .attr("data-value", currentVal);
                $(dataLists).each(function(i, ele) {
                    if (currentVal == i + 1 && outputView) {
                        if ($(ele).find("span.dash").length) outputView.text(removeTags(ele.innerHTML.replace("-", "")));
                        else outputView.text(removeTags(ele.innerHTML));
                    }
                });
            })
            .on("mousedown touchstart", function() {
                $(this).addClass("slidedThumb");
            });

        $('#myTabs a[data-toggle="tab"]').on("shown.bs.tab", function(e) {
            var currentTab = e.target.id; // newly activated tab id

            $("#myTabs .tab-indicators").removeClass("active");
            $(`#myTabs .tab-indicators[data-tab='${currentTab}']`).addClass("active");
        });
        $('#myTabsJourney3 a[data-toggle="tab"]').on("shown.bs.tab", function(e) {
            var currentTab = e.target.id; // newly activated tab id

            $("#myTabsJourney3 .tab-indicators").removeClass("active");
            $(`#myTabsJourney3 .tab-indicators[data-tab='${currentTab}']`).addClass("active");
        });
    });
}

//Prev and Next buttons show and hide
function courselControls(e) {
    var $this = $("#carousel-ddg"),
        nextSlide = $(e.relatedTarget),
        nextBtn = $this.find(".carousel-control.right"),
        prevBtn = $this.find(".carousel-control.left"),
        singleItem = nextSlide.hasClass("single-item"),
        checkBoxGroup = nextSlide.find(".checkbox-group, .checkbox-body"),
        rangeSliderGroup = nextSlide.find(".range-slider-group .slidecontainer input"),
        radioGroup = nextSlide.find(".radio-group"),
        direction = e.direction;

    prevBtn.show();
    nextBtn.hide();

    triggerISI();
    //hide the BACK on first slide
    if (nextSlide.index() == 0) {
        prevBtn.hide();
    }

    // Check validation when slided to next slide
    if (checkBoxGroup.length) {
        checkBoxValidation();
    } else if (rangeSliderGroup.length) {
        rangeSliderValidation();
    } else if (radioGroup.length) {
        if (radioGroup.find("input:checked").length > 0) {
            nextBtn.show();
        }
    }

    // Run on any change
    rangeSliderGroup.on("change click touchend", function() {
        if (singleItem) {
            delayNextSlide();
        } else {
            rangeSliderValidation();
        }
    });

    checkBoxGroup.change(function() {
        checkBoxValidation();
        triggerISI();
    });

    let isChecked = nextSlide
        .prev()
        .find(".dynamic-question")
        .find("input:checked")[0];
    if (isChecked) {
        if (isChecked.id == "impact-1-no") {
            resetRange(1);
            skipItem(direction);
        } else if (isChecked.id == "impact-1-yes") {
            resetRange(5);
        }
    }

    if ($(".carousel-control.get-results-btn").length && nextSlide.is(":last-child")) {
        $(".carousel-control.get-results-btn").removeClass("hidden");
        $(".carousel-control.next").addClass("hidden");
    } else {
        $(".carousel-control.get-results-btn").addClass("hidden");
        $(".carousel-control.next").removeClass("hidden");
    }

    progressBar(nextSlide.data("step"));

    function resetRange(change) {
        let range = document.getElementById("range-reset");
        if (range !== null && range.getAttribute("max") != change) {
            range.setAttribute("max", change);
        }
    }
    function skipItem(direction) {
        //skips the slide based on which side your coming from
        if (direction == "left") {
            $("#carousel-ddg").carousel("next");
        } else {
            $("#carousel-ddg").carousel("prev");
        }
        //resets the slider if yes-no-yes picked
        document.getElementById("slide-container").setAttribute("data-value", 1);
        document.getElementById("reset-text").innerText = "0 Surgeries";
    }

    function checkBoxValidation() {
        nextBtn.hide();
        if (nextSlide.find('input[type="checkbox"]:checked').length > 0) {
            nextBtn.show();
            $(".isi.sticky").removeClass("hidden");
        }
    }

    function rangeSliderValidation() {
        nextBtn.hide();
        var isValid = rangeSliderGroup.toArray().every(function(e) {
            return e.parentNode.dataset.value > 0;
        });

        if (rangeSliderGroup.length > 0 && isValid) {
            nextBtn.show();
        }
    }
}

function delayNextSlide() {
    $("#carousel-ddg")
        .find(".carousel-control.right")
        .show();
    updateProgressbar();
}

export default ddgCore;
