import ddgCore from "./ddgCore";
import ddgResults from "./ddgResults";

function ddgInit() {
    if (document.querySelector(".carousel-ddg-container")) {
        ddgCore();
        ddgResults();
    }
}

export default ddgInit;
